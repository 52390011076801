import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';
import Pagination from 'reactjs-hooks-pagination';

const CustomerView = () => {
    const companyId = ls.get("company_id");
    const OperatorId = ls.get("operator_id");
    const navigate = useNavigate();
    const [CompanyNameList, setCompanyNameList] = useState("");
    const [OperatorName, setOperatorName] = useState("");
    const [customersInfo, setCustomerId] = useState("");
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [PageNn, setPageNo] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const pageLimit = PageNn;
    let onSelectedData = [];

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        Assignview();
    }, [])

    const Assignview = () => {
        let request = {
            company_id: companyId,
            operator_id: OperatorId
        };
        API.post('assignlistbyid/condition ', request).then((response) => {
            if (response.data.success == true) {
                setCompanyNameList(response.data.data[0].company_name);
                setOperatorName(response.data.data[0].operatorname);
                setCustomerId(response.data.data)
                setTotalRecords(response.data.data.length)
                setLoading(false);
                setCurrentPage(1);
                setPageNo(10);

            }
        });
    }

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/AssignList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View Assign List</h5>
                        </div>
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label htmlFor="first-name" className="col-form-label">Company Name </label>
                                                <input type="text" className="form-control" id="first-name" value={CompanyNameList} onChange={(e) => setCompanyNameList(e.target.value)} disabled/>
                                              
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="last-name" className="col-form-label">Operator Name </label>
                                                <input type="text" className="form-control" id="last-name" value={OperatorName} onChange={(e) => setOperatorName(e.target.value)} disabled />
                                            </div>
                                        </div>
                                        <div className="rounded table-responsive customer-table">
                                            <table className="table bg-white">
                                                <thead>
                                                        <th  /* onClick={sortByListName} */>List Name</th>
                                                </thead>
                                                <tbody>
                                                    {customersInfo.length > 0 ? customersInfo.sort((a, b) => b.customersInfo - a.customersInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                                        .map((customersInfo, index) => {
                                                            onSelectedData = [...onSelectedData, customersInfo]
                                                            return (<tr key={index}>
                                                                <td>{customersInfo.list_name}</td>
                                                                <td>
                                                                </td>
                                                            </tr>)
                                                        }
                                                        ) : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                        <ul className="pagination">
                                            <Pagination className=""
                                                totalRecords={totalRecords}
                                                pageLimit={pageLimit}
                                                pageRangeDisplayed={1}
                                                onChangePage={setCurrentPage}
                                            />
                                        </ul>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-close-btn"  onClick={() => navigate('/AssignList')}>Close</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default CustomerView;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
//import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
//import moment from 'moment';



const ListName = () => {
    const [ListInfo, setListInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
   // const [search, setSearch] = useState("");
    // const [Companyname, setCompanyname] = useState("");
    // const [AdminName, setAdminName] = useState("");

    // const [status, setStatus] = useState("");
  
    const [PageNn, setPageNo] = useState("");
    const [Count, setCount] = useState("");


    const pageLimit = PageNn;

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        ListName();
    }, [])
    // ============================List===============================
    const ListName = () => {
        API.post('customerlistnamefull/condition',).then((response) => {
            if (response.data.success == true) {
                setListInfo(response.data?.data);
                setTotalRecords(response.data.data?.length);
                setCount(response.data.data1[0].count);
                document.getElementById("page").value = "10";
                setCurrentPage(1);
                setLoading(false);
                setPageNo(10);
            }
        });
    }

    // =========================Search============================

    // const onSearch = (e) => {
    //     setSearch(e.target.value);
    // }

    // const adminSearch = () => {
    //     let request = {
    //         data: search
    //     }

    //     API.post("companysearch/condition", request).then(response => {
    //         setListInfo(response.data?.data);
    //         setCount(response.data.data1[0].count);
    //         setTotalRecords(response.data.data?.length);
    //         setCurrentPage(1);
    //     });
    // }

    // useEffect(() => {
    //     if (search != "") {
    //         const timer = setTimeout(() => {
    //             adminSearch();
    //         }, 200);
    //         return () => {
    //             clearTimeout(timer);
    //         };
    //     } else {
    //         setLoading(true);
    //         ListName();
    //     }
    // }, [search]);

    // const onKeydownAccountSearch = (event) => {
    //     if (event.key === "Enter") {
    //         adminSearch();
    //     }
    // };
    //const yesterday = moment().subtract(1, 'day');
    // const disablePastDt = current => {
    //     return current.isSameOrAfter(fromdate);
    // };

    // const inputProps = {
    //     placeholder: "From Date",
    //     value: fromdate
    // };
    // const inputProps1 = {
    //     placeholder: "To Date",
    //     value: todate
    // };

    // const fromDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         setfromdate(event.format("YYYY-MM-DD"))
    //         settodate("");
    //     }
    // }
    // const toDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         settodate(event.format("YYYY-MM-DD"))
    //     }
    // }

    // const adminFilter = () => {
      


    //     setLoading(true)
    //     let request = {
    //         adminname: AdminName,
    //         company_name: Companyname,
    //         active: status
           
    //     }

    //     API.post("companyfilter/condition", request).then(response => {
    //         setListInfo(response.data?.data);
    //         setTotalRecords(response.data.data?.length);
    //         setCount(response.data.data1[0].count);
    //         setLoading(false)
    //         document.getElementById("page").value = "10";
    //         setCurrentPage(1);
    //         setPageNo(10);
    //     });
    // }

    // const handleSearch = () => {
    //     adminFilter();
    // }

    // const handleclear = () => {

    //     setCompanyname("");
    //     setAdminName("");
       
    //     setStatus("");
    
    //     const statusSelect = document.getElementById("status");
    //     statusSelect.selectedIndex = 0;
    //     setLoading(true);
    //     ListName();

    //     document.getElementById("page").value = "10";
    // }




    //asperpage

    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...ListInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.customer_list_id - userB.customer_list_id;
            }
            return userB.customer_list_id - userA.customer_list_id;
        });
        setListInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
    };
    const sortByCompanyName = () => {
        console.log("sortById start");
        const usersCopy = [...ListInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.company_name || '';
            userB = userB.company_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setListInfo(usersCopy);
        setSorted({ sorted: "company_name", reversed: !sorted.reversed });

    };

    const sortByListName = () => {
        const usersCopy = [...ListInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.list_name.localeCompare(userA.list_name);
            }
            return userA.list_name.localeCompare(userB.list_name);
        });
        setListInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });
    };
 



  

//    function formatTime(value) {
//         let local = ""
//        // let offset = ""
//         // let utc1 = ""
//        // console.log(value, "yyyy1");
//        value = value.replace(" ","T")
//        value = value+"Z";
//        console.log(value, "yyyy1");
//         local = new Date(value);
//        value =local.toLocaleString();
//         //var local = new Date("2023-05-22 08:21:11");
//         // offset = local.getTimezoneOffset();
//         // value = new Date(local.getTime() - offset * 60000);
//         // console.log(value, "yyy");
//          value = moment(value).format('MMMM D, YYYY hh:mm A')
//         // let utc2222 = utc1
//         console.log(value, "yyyy");
//         return value;
//     }





    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text"> List Name</h5>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec">
                               {/*  <div className="search-bar">
                                    <input type="text" className="form-control" value={Companyname} placeholder="list Name" onChange={(e) => setCompanyname(e.target.value)} />
                                </div> */}
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={AdminName} placeholder="Admin Name" onChange={(e) => setAdminName(e.target.value)} />
                                </div> */}

                                {/* <div className="col-lg-2 me-2">
                                       
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >Status</option>
                                            <option value="1">Active</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                        </div> */}


                                {/* <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div> */}
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <div className='add-section'>
                                {/* <div className="search-bar">
                                    <input type="search" className="form-control" value={search} placeholder="List Name or Id  " onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div> */}
                                <div className="add-btn" onClick={() => navigate('/ListAdd')}><i className="fa-solid fa-plus"></i>Add</div>
                            </div>
                        </div>
                    </div>


                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                    <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>
                        </span>


                    </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">

                                <table className="table bg-white">
                                    <thead>
                                        <tr>                                         
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCompanyName}>Company Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Description</th>
                                            <th>Status</th>
                                           
                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ListInfo.length > 0 ? ListInfo.sort((a, b) => b.ListInfo - a.ListInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((ListInfo, index) =>
                                                <tr key={index}>
                                                    <td>{ListInfo.customer_list_id}</td>
                                                    <td>{ListInfo.company_name}</td>
                                                    <td>{ListInfo.list_name}</td>
                                                    {ListInfo.description === null || ListInfo.description === "null" ? <td></td> : <td>{ListInfo.description}</td>}
                                                    <td>{ListInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>
                                                   
                                                    <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate('/ListEdit', ls.set("customer_list_id", ListInfo.customer_list_id))}>Edit</a>
                                                        <a className="edit" onClick={() => navigate('/ListView', ls.set("customer_list_id", ListInfo.customer_list_id))}>View</a></td>
                                                        

                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}

                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default ListName;
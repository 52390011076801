import React, { useState, useEffect } from 'react';

//import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
//import Loading from '../../Component/Loading/Loading';
//import { read, utils } from 'xlsx';
//import ls from 'local-storage';
import checkImg from '../../../src/assets/img/check.png';
import errorImg from '../../../src/assets/img/uncheck.png';
import Datetime from 'react-datetime';
import moment from 'moment';


const Recycle = (props) => {
    const userData = useSelector(state => state.LoginReducer.payload);
    const [toggleZipcodePopupOpen, setToggleZipcodePopupOpen] = useState(true);
    const [togglePopupOpen, setTogglePopupOpen] = useState(false);

    const [alertimg, setAlertImg] = useState("");
    const [date, setdate] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [Todate, setTodate] = useState("");

    //error msg
    const [DateError, setDateError] = useState("");
    const [FromdateError, setFromdateError] = useState("");
    const [TodateError, setTodateError] = useState("");

    const [popupTitle, /* setPopupTitle */] = useState("");
    const [popupMsg, /* setPopupMsg */] = useState("");
    const [popupType, /* setPopupType */] = useState("");
    const [popupActionType, /* setPopupActionType */] = useState("");
    const [popupActionValue, /* setPopupActionValue */] = useState("");

    const [alertmessage, setAlertMessage] = useState("");
    const [alerttitle, setAlertTitle] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    //const [loading, /* setLoading */] = useState(true)
    const [CompanyName, setCompanyName] = useState("");
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [CompanyId, setCompanyId] = useState("");
    const [CompanyNameError, setCompanyNameError] = useState("");


    const [OperatorName, setOperatorName] = useState("");
    const [selectedOperatorName, setselectedOperatorName] = useState("");
    const [/* OperatorId */, setOperatorId] = useState("");

    const [ListName, setListName] = useState("");
    const [SelectedListName, setSelectedListName] = useState("");
    const [/* ListId */, setListId] = useState("");
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    // const [isOpen, setIsOpen] = useState(false);
    // const recallfunction = (data) => {
    //     // console.log('reCalling==', data);
    //     setIsOpen(data);
    // };

    const handleReset = () => {
        setDateError("");
        setFromdateError("");
        setTodateError("");

        if (!date) {
            setDateError("Date is required");
            return;
        }

        if (!fromdate) {
            setFromdateError("From Date is required");
            return;
        }

        if (!Todate) {
            setTodateError("To Date is required");
            return;
        }
        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }
        setToggleZipcodePopupOpen(false);
        setTogglePopupOpen(true);


    };

    const handleSubmit = () => {
        let request = {
            recycle_date: date,
            from_date: fromdate,
            to_date: Todate,
            company_id: selectedCustomerName.company_id,
            operator_id: /* !selectedOperatorName ? "" :  */selectedOperatorName.operator_id,
            customer_list_id: /* !SelectedListName ? "" : */ SelectedListName.customer_list_id,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id,
        };

        API.post('recycle/add ', request).then((response) => {
            if (response.data.success == true) {
                setTogglePopupOpen(false);
                setAlertTitle("Recycle Customer");
                setAlertMessage("Recycle Created Succesfully");
                setAlertImg(checkImg);
                props.reCallFunction();
            }
            else {
                setAlertTitle("Add Recycle Customer");
                setAlertImg(errorImg);
                setAlertMessage(response.data.error.err);
            }
        });
    }

    const inputPropsdate = {
        placeholder: "DD/MM/YYYY",
        value: date
    };

    const yesterday = moment().subtract(1, 'day');
    const disablePastDate = current => {
        return current.isAfter(yesterday);
    };
    const registrationDate = (event) => {
        if (event._isAMomentObject == true) {
            setdate(event.format("YYYY-MM-DD"));
        }
    }

    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: Todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            setTodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            setTodate(event.format("YYYY-MM-DD"))
        }
    }

    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    useEffect(() => {
        handleCompanyName();
        // handleOperatorName();
        // handleListName();

    }, [])

    const handleCompanyName = () => {

        API.post("companyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    // const handleOpeartorName = () => {

    //     API.post("operatorlist/condition").then(response => {
    //         setOperatorName(response.data.data);
    //     });
    // }
    const handleListName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }
        API.post("customerlistassgin/condition", request).then(response => {
            setListName(response.data.data);
        });
    }

    const handleOperatorName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }

        API.post("operatorbycompanyid/condition", request).then(response => {
            setOperatorName(response.data.data);
            //setLoading(false);
        });
    }

    const handleCompany = (e) => {
        // setCompanyId(e.target.value);
        // const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        // setSelectedCustomerName(selectedCustomerDetails);
        setCompanyId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedCustomerName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        handleOperatorName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
        handleListName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);

    }

    // const handleOperator = (e) => {
    //     setOperatorId(e.target.value);
    //     const selectedOpertorDetails = OperatorName.filter(data => data.operator_id == e.target.value)[0]
    //     setselectedOperatorName(selectedOpertorDetails);

    // }

    const handleOperator = (e) => {
        setOperatorId(e.target.value);
        console.log("=========check", e.target.value);
        setselectedOperatorName(
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
    };
    const handleListId = (e) => {
        setListId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedListName(
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    };


    return (

        <div >
            <div>
                {/* {loading ? <Loading /> : */}
                {toggleZipcodePopupOpen ?
                    (<>
                        <div className="popup-box">
                            <div className="models-box recycle">
                                <div className="main-contents">
                                    <div className="modal-bodys">
                                        <div className="row">
                                            <div className="back-heading">
                                                <h5 className="heading-text">Recycle Customer</h5>
                                            </div>

                                            <div className="col-xl-4  form-group datePickerBlock recycle">
                                                <div className="tbox">
                                                    <div className="textbox">
                                                        <label htmlFor="assignDate" className="col-form-label" >Reset</label>
                                                        <Datetime inputProps={inputPropsdate}
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            dateFormat="DD/MM/YYYY"
                                                            name="Date"
                                                            id="meeting_date"
                                                            isValidDate={disablePastDate}
                                                            onChange={registrationDate}
                                                        />
                                                    </div>
                                                </div>
                                                <p className="form-input-error">{DateError}</p>
                                            </div>


                                            <div className="col-xl-4  form-group datePickerBlock recycle">
                                                <div className="tbox">
                                                    <div className="textbox">
                                                        <label htmlFor="assignDate" className="col-form-label" >From Date</label>
                                                        <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                                            name="Date" onChange={fromDate} closeOnSelect={true}
                                                            id="meeting_date" />
                                                    </div>
                                                </div>
                                                <p className="form-input-error">{FromdateError}</p>
                                            </div>

                                            <div className="col-xl-4  form-group datePickerBlock recycle">
                                                <div className="tbox">
                                                    <div className="textbox">
                                                        <label htmlFor="assignDate" className="col-form-label" >To Date</label>
                                                        <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                                            name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                                            id="meeting_date" />
                                                    </div>
                                                </div>
                                                <p className="form-input-error">{TodateError}</p>
                                            </div>

                                            <div className="col-xl-4  form-group datePickerBlock recycle ">
                                                <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="customerName" onChange={handleCompany} >
                                                    <option style={{ display: "none" }}>Select CompanyName</option>
                                                    {CompanyName.length > 0 &&
                                                        <>
                                                            {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{CompanyNameError}</p>
                                            </div>

                                           

                                            {selectedCustomerName === "" ?
                                            <div className=" col-xl-4  form-group datePickerBlock recycle">
                                                <label htmlFor="customerName" className="col-form-label">Operator Name </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    id="Sale"
                                                    disabled
                                                >
                                                    <option style={{ display: "none" }}>Select OperatorName</option>
                                                    {/* {OperatorName?.length > 0 && (
                                                 <>
                                                     {OperatorName.map((OperatorNameID) => (
                                                         <option
                                                             key={OperatorNameID.operator_id}
                                                             value={OperatorNameID.operator_id}
                                                         >
                                                             {OperatorNameID.operator_name}
                                                         </option>
                                                     ))}
                                                 </>
                                             )} */}
                                                </select>
                                            </div>
                                            :
                                            <div className="col-xl-4  form-group datePickerBlock recycle">
                                                <label htmlFor="customerName" className="col-form-label">Operator Name </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    id="Sale"
                                                    onChange={handleOperator}

                                                >
                                                    <option style={{ display: "none" }}>Select OperatorName</option>
                                                    {OperatorName?.length > 0 && (
                                                        <>
                                                            {OperatorName.map((OperatorNameID) => (
                                                                <option
                                                                    key={OperatorNameID.operator_id}
                                                                    value={OperatorNameID.operator_id}
                                                                >
                                                                    {OperatorNameID.operatorname}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                            <div className="col-xl-4  form-group datePickerBlock recycle">
                                            <label htmlFor="customerName" className="col-form-label">List Name </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div>


                                        </div>




                                        <div className="modal-footer">

                                            <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={props.toggle}>Close</button>

                                            <button type="button" className="btn form-submit-btn" onClick={handleReset}>Submit </button>




                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) :
                    togglePopupOpen ?
                        <>
                            <div className="popup-box">
                                <div className=" recycle">
                                    <div className='successpopup'>
                                        <div className="row">
                                            <div className="back-heading">
                                                <h5 className="heading-text">Confirmation</h5>
                                                <p className='Para-text'>Are You Sure, Do you want to Submit</p>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={props.toggle}>Close</button>
                                            <button type="button" className="btn form-submit-btn" onClick={handleSubmit}>Save </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        (
                            <div className="popup-box">
                                <div className=" recycle">
                                    <div className='successpopup'>
                                        <div className="Commonfull-icon">
                                            <img className={alertimg === checkImg ? "successImg " : "errorImg"} src={alertimg} />
                                        </div>
                                        <div className="CommonModalbody">
                                            <h2>{alerttitle}</h2>
                                            <p>{alertmessage}</p>
                                        </div>
                                        <div className="CommonModalfooter ">
                                            <div className="CommonModalfooter session">

                                                <button className="btn btn-smlprimary" onClick={props.toggle}>Ok</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}



            </div>
            {
                isOpenPopup && (
                    <CommonPopup
                        handleClose={togglePopup}
                        popupTitle={popupTitle}
                        popupMsg={popupMsg}
                        popupType={popupType}
                        popupActionType={popupActionType}
                        popupActionValue={popupActionValue}
                    // popupActionPath={popupActionPath}
                    />
                )
            }
        </div>



    );
};
export default Recycle;
import React, { useState } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import adduser from '../../assets/img/adduser.jpg';
import FileBase64 from 'react-file-base64';



const CompanyAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [Companyname, setCompanyname] = useState("");
    const [Description, setDescription] = useState("");
    const [State, setState] = useState("");
    const [City, setCity] = useState("");
    const [Zipcode, setZipcode] = useState("");

    // errormsg
    const [CompanynameError, setCompanynameError] = useState("");

    //logo
    const [image,] = useState("");
    const [doc, setDoc] = useState("");
    const [type, setType] = useState("");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [submit,setSubmit] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };


    const handleAdd = () => {
        setCompanynameError("");

        if (!Companyname) {
            setCompanynameError("Company Name is required");
            return;
        }

        let request = {
            company_image: doc === "" ? "" : doc.length > 0 ? doc : [doc],
            company_name: Companyname,
            description: Description,
            state: State,
            city: City,
            zipcode: Zipcode,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        setSubmit(true);
        API.post('company/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add New Company");
                setPopupMsg("Company Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/CompanyList");
            }
            else {
                // setTimeout(() => {

                //   }, 100);
                togglePopup();
                setPopupTitle("Add New Company");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const getFiles = (file) => {
        console.log("================>", file.type)
        setType("")
        if (file.type.includes("jpg") || file.type.includes("jpeg") || file.type.includes("png")) {
            setDoc(file);
        } else {
            setType("0");
        }
    }




    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/CompanyList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New Company</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">

                                        <div className="col-lg-12 ">

                                            <div className="user-upload-btn-wrapper">
                                                {(image === "" || image == null || image == undefined) && doc === "" ? <img alt="" src={adduser} /> :
                                                    doc === "" ? <img alt="" src={image} /> :
                                                        <img alt="" src={doc.base64} />}
                                                <span className="proCamera"></span>
                                                <FileBase64 onDone={getFiles} type="hidden" />

                                                {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                            </div>
                                        </div>


                                        <div className="col-lg-4">
                                            <label htmlFor="Companyname" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="Companyname" onChange={(e) => setCompanyname(e.target.value)} />
                                            <p className="form-input-error">{CompanynameError}</p>
                                        </div>

                                        <div className="col-lg-4">

                                            <label htmlFor="first-name" className="col-form-label">Description </label>
                                            <input type="text" className="form-control" id="Description" onChange={(e) => setDescription(e.target.value)} />
                                        </div>

                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">State </label>
                                            <input type="text" className="form-control" id="State" onChange={(e) => setState(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">City </label>
                                            <input type="text" className="form-control" id="City" onChange={(e) => setCity(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">Zipcode </label>
                                            <input type="text" className="form-control" id="Zipcode" onChange={(e) => setZipcode(e.target.value)} />
                                        </div>





                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd} disabled={submit}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default CompanyAdd;
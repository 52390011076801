import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import Loading from '../../Component/Loading/Loading';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import PhoneInputs from 'react-phone-input-2';
import ls from 'local-storage';

const CustomerEdit = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [pNumber, setPNumber] = useState("");
    const [comment, setComment] = useState("");
    const [Vehicle, setVehicle] = useState("");
    const [Stock, setStock] = useState("");
    const [Color, setColor] = useState("");
    const [Source, setSource] = useState("");
    const [status, setStatus] = useState("");
    const [Version, setVersion] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    //const [emailError, setEmailError] = useState("");
    const [pNumberError, setPNumberError] = useState("");
    // const [status_name, setstatus_name] = useState("");
    //const [commentError, setCommentError] = useState("");
    // const [ StatusError , setStatusError] = useState("");
    const [loading, setLoading] = useState(true)
    const [, setListId] = useState("");

    

    const [, setOperatorId] = useState("");
    const [, setSelectedOperatorName] = useState("");
    const [OperatorName, setOperatorName] = useState("");
    const [OperatorNameValues, setOperatorNameValues] = useState("");
    const [OperatorNameId, setOperatorNameId] = useState("");

    const [CompanyName, setCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState("");
    const [CompanyNameError, setCompanyNameError] = useState("");
    const [, setSelectedCompanyName] = useState("");
    //const location = useLocation()
   // const CustomerId = location.state.CustomerId;
   const [ListName, setListName] = useState("");
   const [ListNameId, setListNameId] = useState("");
   const [, setSelectedListName] = useState("");
   const [ListNameValues, setListNameValues] = useState("");
    const CustomerId = ls.get("AdminId");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };
    console.log(ListNameId,"ListNameId");
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        handleCompanyName();
        
    }, [])
    

    const handleCompanyName = () => {

        API.post("mapcompanyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const handleCompany = (e) => {
        setListNameValues("");
        setOperatorNameValues("") // Reset List Name values
        setListNameId("")
        setOperatorNameId("")
        setCompanyId(e.target.value);
        const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        setSelectedCompanyName(selectedCustomerDetails);
       
        handleListName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
        handleOperatorName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);

    }

    const handleListName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }
      

        API.post("customerlistassgin/condition", request).then(response => {
            setListName(response.data.data);
           // setListNameId("")
        });

    }
    const handleListId = (e) => {
        setListId(e.target.value);
       
        console.log("=========check", e.target.value);
        setSelectedListName(
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        const listname = ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        setListNameValues(listname.list_name)
        setListNameId(listname.customer_list_id)
    };

    const handleOperatorName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }
      

        API.post("operatorbycompanyid/condition", request).then(response => {
            setOperatorName(response.data.data);
            //setOperatorNameId("")
        });

    }

    const handleOperatorId = (e) => {
        setOperatorId(e.target.value);
       
        console.log("=========check", e.target.value);
        setSelectedOperatorName(
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        const operatorname = OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        setOperatorNameValues(operatorname.operatorname)
        setOperatorNameId(operatorname.operator_id)
    };

    useEffect(() => {
        Operatorview();
    }, [])

    const Operatorview = () => {
        let request = {
            customer_id: CustomerId
        };
        API.post('customerview/condition ', request).then((response) => {
            if (response.data.success == true) {
                handleListName(response.data.data[0])
                handleOperatorName(response.data.data[0])
                setFirstName(response.data.data[0]?.first_name);
                setLastName(response.data.data[0]?.last_name);
                setEmail(response.data.data[0]?.email);
                setPNumber(response.data.data[0]?.Phone);
                // setstatus_name(response.data.data[0]?.status_name);
                setComment(response.data.data[0]?.CommentsByAdmin);
                setStatus(response.data.data[0]?.status);
                setVehicle(response.data.data[0]?.vehicle);
                setStock(response.data.data[0]?.stock);
                setColor(response.data.data[0]?.color);
                setSource(response.data.data[0]?.source);
                setVersion(response.data.data[0]?.version);
                setCompanyId(response.data.data[0]?.company_id)
                setListNameId(response.data.data[0]?.customer_list_id)
                setOperatorNameId(response.data.data[0]?.operator_id)
                setListNameValues(response.data.data[0]?.list_name)
                setOperatorNameValues(response.data.data[0]?.operatorname)
                setLoading(false);
            }
        });
    }
    
    const handleEdit = () => {
        setFirstNameError("");
        setLastNameError("");
       // setEmailError("");
        setPNumberError("");
       // setCommentError("");
       // setStatusError("");
       setCompanyNameError("")

        if (!firstName) {
            setFirstNameError("First Name is required");
            return;
        }
        if (!lastName) {
            setLastNameError("Last Name is required");
            return;
        }
        // if (!email) {
        //     setEmailError("Email is required");
        //     return;
        // }
        // else if (email && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email)) {
        //     setEmailError('Must match the Username format');
        //     return;
        // }
        if (!pNumber) {
            setPNumberError("Phone Number is required");
            return;
        }
        else if (pNumber.length < 10) {
            setPNumberError("Phone Number must have 10 digits");
            return;
        }
        else if (pNumber.length > 10) {
            setPNumberError("Phone Number accept only 10 digits");
            return;
        }
        // if (!comment) {
        //     setCommentError("Comment is required");
        //     return;
        // }
        //    if (!status) {
        //       setStatusError("Status is required");
        //       return;
        //   }
        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }

        let request = {
            customer_id: CustomerId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            status: status === "" ? "Active":status,
            Phone: pNumber,
            vehicle: Vehicle,
            version:Version,
            stock:Stock,
            color:Color,
            source:Source,
            active: status==="Active" ? 1 : 4,
            company_id: CompanyId,
            customer_list_id: ListNameId, 
            operator_id: OperatorNameId,
            CommentsByAdmin: comment,
        };
        console.log(request,"customeredit");
        
        API.post('customeredit/update ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Edit Customer");
                setPopupMsg("Customer Updated Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/customers");
            }
            else {
               
                togglePopup();
                setPopupTitle("Edit Customer");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };
    const handleDropdownChange = (event) => {
        console.log("event",event);
        setStatus(event.target.value);
    
        // if (event.target.value === "Active") {
        //   window.alert("Option selected: Active");
        // }
       
      };

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/customers')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Edit Customer</h5>
                        </div>

                        <div className="rounded table-responsive">

                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-4 ">
                                                <label htmlFor="first-name" className="col-form-label">First Name <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                <p className="form-input-error">{firstNameError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="last-name" className="col-form-label">Last Name <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                <p className="form-input-error">{lastNameError}</p>
                                            </div>

                                            <div className="col-lg-4">
                                                <label htmlFor="email" className="col-form-label">Email </label>
                                                <input type="text" className="form-control" id="email" value={email === null || email === "null" ? "" :email} onChange={(e) => setEmail(e.target.value)} />
                                                {/* <p className="form-input-error">{emailError}</p> */}
                                            </div>

                                            <div className="col-lg-4">
                                                {/* <label htmlFor="phone-number" className="col-form-label">Phone Number <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="phone-number" value={pNumber} onChange={(e) => setPNumber(e.target.value)} /> */}
                                                 <label htmlFor="phone-number" className="form-label required"> Phone</label>
                                                <PhoneInputs
                                                class="form-control mt-5"
                                                country={'us'}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                onlyCountries={['us']}
                                                value={pNumber}
                                                onChange={(e) => setPNumber(e)}
                                                placeholder=""
                                            ></PhoneInputs>
                                                <p className="form-input-error">{pNumberError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="comment" className="col-form-label">Comments</label>
                                                <input type="text" className="form-control" id="comment" value={comment === null || comment === "null" ? "" :comment} onChange={(e) => setComment(e.target.value)} />
                                                {/* <p className="form-input-error">{commentError}</p> */}
                                            </div>
                                            <div className="col-lg-4">
                                        <label htmlFor="status" className="col-form-label">Status{/*  <span className='mandatory ms-1'>*</span> */}</label>
                                        <select className="form-select" id="status" value={status}  onChange={handleDropdownChange}>
                                            <option selected style={{ display: "none" }}  >{status == "Active"||status == 1 ? "Active" :"Inactive"}</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            <option value="Dontcall">Don&rsquo;t call</option>
                                        </select>
                                        {/* <p className="form-input-error">{StatusError}</p>  */}
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Vehicle" className="col-form-label">Vehicle</label>
                                            <input type="text" className="form-control" id="Vehicle" value={Vehicle === null || Vehicle === "null" ? "" :Vehicle} onChange={(e) => setVehicle(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Stock" className="col-form-label">Stock {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Stock" value={Stock === null || Stock === "null" ? "" :Stock} onChange={(e) => setStock(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Color" className="col-form-label">Color {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Color" value={Color === null || Color === "null" ? "" :Color} onChange={(e) => setColor(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Source" className="col-form-label">Source {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Source" value={Source === null || Source === "null" ? "" :Source} onChange={(e) => setSource(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <select className="form-select" id="customerName"  value={CompanyId} onChange={handleCompany} >
                                                <option style={{ display: "none" }}>Select CompanyName</option>
                                                {CompanyName.length > 0 &&
                                                    <>
                                                        {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{CompanyNameError}</p>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="ListName" className="col-form-label">List Name {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <select className="form-select" id="ListName"  value={ListNameId} onChange={handleListId} >
                                                <option style={{ display: "none" }}>{ListNameValues}</option>
                                                {ListName?.length > 0 &&
                                                    <>
                                                        {ListName.map((data) => <option key={data.customer_list_id} value={data.customer_list_id} >{data.list_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="OperatorName" className="col-form-label">Operator Name {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <select className="form-select" id="OperatorName"  value={OperatorNameId} onChange={handleOperatorId} >
                                                <option style={{ display: "none" }}>{OperatorNameValues}</option>
                                                {OperatorName?.length > 0 &&
                                                    <>
                                                        {OperatorName.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operatorname}</option>)}
                                                    </>
                                                }
                                            </select>
                                            
                                        </div>
                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleEdit}>Update</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default CustomerEdit;							
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';


const Operator = () => {
    const navigate = useNavigate();
    const [PageNn, setPageNo] = useState("");

    const pageLimit = PageNn;
    const [operatorInfo, setOperatorInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [/* operatorName */, setOperatorName] = useState("");
    const [userName, setUserName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");
    const [Count, setCount] = useState("");
    const [status, setStatus] = useState("");

    const [CompanyName, setCompanyName] = useState("");
    const [SelectedCompanyName, setSelectedCompanyName] = useState("");
    const [/* operator */, setCompanyid] = useState("");




    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        OperatorList();
    }, [])

    console.log("count", Count);

    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {
                setOperatorInfo(response.data.data);
                setoperatorID(response.data.data);
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data.length);
                document.getElementById("page").value = "10";
                setCurrentPage(1);
                setLoading(false);
                setPageNo(10);
            }
        });
    }

    useEffect(() => {
        handleCompanyName();

    }, [])

    const handleCompanyName = () => {

        API.post("companyListview/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const OperatorSearch = () => {
        let request = {
            data: search
        }

        API.post("operatorsearch/condition", request).then(response => {
            setOperatorInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setCount(response.data.data1[0].count);
            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                OperatorSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setLoading(true);
            OperatorList();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            OperatorSearch();
        }
    };

    const handleBuyerId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
    };
    const handleCompanyId = (e) => {
        setCompanyid(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedCompanyName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
    };

    const operatorFilter = () => {
        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            operator_name: "",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            user_name: userName,
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            active: status

        }

        API.post("operatorfilter/condition", request).then(response => {
            setOperatorInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setCount(response.data.data1[0].count);
            setLoading(false)
            document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const handleSearch = () => {
        operatorFilter();
    }

    const handleclear = () => {
        setOperatorName("");
        setUserName("");
        setoperatorID("");
        setCompanyName("");
        setPhoneNo("");
        setfromdate("");
        settodate("");
        setStatus("");
    
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
        setLoading(true);
        OperatorList();
        handleCompanyName();
        setFromDateError();
        document.getElementById("page").value = "10";
        // document.getElementById("userName").value = "Select userName";
    }
    const redirection = (id) => {

        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };



    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.operator_id - userB.operator_id;
            }
            return userB.operator_id - userA.operator_id;
        });

        setOperatorInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });


    };

    const sortByOperatorName = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.operatorname.localeCompare(userA.operatorname);
            }
            return userA.operatorname.localeCompare(userB.operatorname);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "operatorname", reversed: !sorted.reversed });
    };

    const sortByUserName = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.user_name.localeCompare(userA.user_name);
            }
            return userA.user_name.localeCompare(userB.user_name);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "username", reversed: !sorted.reversed });
    };

    const sortByEmail = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.email.localeCompare(userA.email);
            }
            return userA.email.localeCompare(userB.email);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "email", reversed: !sorted.reversed });
    };


    const sortByPassword = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.Password.localeCompare(userA.Password);
            }
            return userA.Password.localeCompare(userB.Password);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "Password", reversed: !sorted.reversed });
    };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.phone - userB.phone;
            }
            return userB.phone - userA.phone;
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "phone", reversed: !sorted.reversed });
    };

    const sortByAdminName = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.adminname.localeCompare(userA.adminname);
            }
            return userA.adminname.localeCompare(userB.adminname);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "adminname", reversed: !sorted.reversed });
    };

    const sortByCompanyName = () => {
        console.log("sortById start");
        const usersCopy = [...operatorInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.company_name || '';
            userB = userB.company_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "company_name", reversed: !sorted.reversed });

    };

    const sortByDatetime = () => {
        const usersCopy = [...operatorInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.createdtime.localeCompare(userA.createdtime);
            }
            return userA.createdtime.localeCompare(userB.createdtime);
        });
        setOperatorInfo(usersCopy);
        setSorted({ sorted: "createdtime", reversed: !sorted.reversed });
    };
    // var utc2222 =[]; 
    // for (let n = 0; n < operatorInfo.length; n++) {
    //     let local = ""
    //     let offset = ""
    //     let utc1 = ""

    //     local = new Date(operatorInfo[n].createdtimes);

    //     //var local = new Date("2023-05-22 08:21:11");
    //     offset = local.getTimezoneOffset();
    //     utc1 = new Date(local.getTime() - offset * 60000);
    //     utc2222[n] = utc1
    //     //console.log(utc1, n ,operatorInfo[n].createdtimes,"yyyy");
    // }

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }

    function formatTime(value) {
        let local = ""
        // let offset = ""
        // let utc1 = ""
        // console.log(value, "yyyy1");
        value = value.replace(" ", "T")
        value = value + "Z";
        console.log(value, "yyyy1");
        local = new Date(value);
        value = local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
        value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Operator</h5>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="common-heading-sec">
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                                </div> */}
                                <div className=" col-md-6 col-lg-2 me-2">
                                    {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleBuyerId}
                                    >
                                        <option style={{ display: "none" }}>Operator Name</option>
                                        {operatorID?.length > 0 && (
                                            <>
                                                {operatorID.map((operatorID) => (
                                                    <option
                                                        key={operatorID.operator_id}
                                                        value={operatorID.operator_id}
                                                    >
                                                        {operatorID.operatorname}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className=" col-md-6 col-lg-2 me-2">
                                    {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleCompanyId}
                                    >
                                        <option style={{ display: "none" }}>Company Name</option>
                                        {CompanyName?.length > 0 && (
                                            <>
                                                {CompanyName.map((CompanyNameID) => (
                                                    <option
                                                        key={CompanyNameID.company_id}
                                                        value={CompanyNameID.company_id}
                                                    >
                                                        {CompanyNameID.company_name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="col-lg-1 me-2">
                                    <input type="text" className="form-control" value={userName} placeholder="Username" onChange={(e) => setUserName(e.target.value)} />
                                </div>
                                <div className="col-lg-1 me-2">
                                    <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>
                                <div className="datePickerBlock">
                                    {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>
                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>
                                <div className="col-lg-1 me-2">
                                        {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >Status</option>
                                            <option value="1">Active</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                        </div>

                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>

                            </div>
                        </div>

                        
                    </div>

                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>

                        </span>

                        <div className='col-xl-4'>
                            <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" placeholder="Name or Id or Phone#" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>
                                <div className="add-btn" onClick={() => navigate('/operatorAdd')} ><i className="fa-solid fa-plus"></i>Add</div>
                            </div>
                        </div>


                    </div>
                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByUserName}>User Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByEmail}>Email Id<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPassword}>Password<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByAdminName}>AdminName<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDatetime}>Date & Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th  onClick={sortByCompanyName}>Company Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Status</th>
                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {operatorInfo.length > 0 ? operatorInfo.sort((a, b) => b.customer_id - a.customer_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((operatorInfo, index) =>
                                                <tr key={index}>

                                                    <td><a onClick={() => redirection(operatorInfo.operator_id)}>{operatorInfo.operator_id}</a></td>
                                                    <td><a onClick={() => redirection(operatorInfo.operator_id)}>{operatorInfo.operatorname}</a></td>
                                                    <td>{operatorInfo.user_name}</td>
                                                    <td>{operatorInfo.email}</td>
                                                    <td>{operatorInfo.Password}</td>
                                                    <td>{formatMobileNO(operatorInfo.phone)}</td>
                                                    <td>{operatorInfo.adminname}</td>
                                                    <td>{formatTime(operatorInfo.createdtimes)}</td>
                                                    <td>{operatorInfo.company_name}</td>
                                                    {/* <td>{operatorInfo.createdtime}</td> */}
                                                    <td>{operatorInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>
                                                    <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate('/operatorEdit', ls.set("AdminId", operatorInfo.operator_id))}>Edit</a>
                                                        <a className="edit" onClick={() => navigate('/OperatorView', ls.set("AdminId", operatorInfo.operator_id))}>View</a></td>
                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default Operator;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import Loading from '../../Component/Loading/Loading';
//import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import ls from 'local-storage';

const AssignWebphoneConfigView = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
 
   
    
    const [operator, setoperator] = useState("");

    console.log(operator,"operatorID");
    const [username, setusername] = useState("");
    const [Password, setPassword] = useState("");
    const [serveraddress, setserveraddress] = useState("");
    const [status, setStatus] = useState("");


    const [loading, setLoading] = useState(true)
    //const location = useLocation()
    const AdminId = ls.get("webphone_config_id");
    //const customerId = ls.get("customrId");


    // const [popupTitle, setPopupTitle] = useState("");
    // const [popupMsg, setPopupMsg] = useState("");
    // const [popupType, setPopupType] = useState("");
    // const [popupActionType, setPopupActionType] = useState("");
    // const [popupActionValue, setPopupActionValue] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
   // const [isOpenPopup, setIsOpenPopup] = useState(false);
    // const togglePopup = () => {
    //     setIsOpenPopup(!isOpenPopup);
    // };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        AssignWebphoneConfigView();
    }, [])

    const AssignWebphoneConfigView = () => {
        let request = {
            assign_webphone_config_id: AdminId,
        };
        API.post('assignwebphoneconfigListbid/condition ', request).then((response) => {
            if (response.data.success == true) {
                setusername(response.data.data[0]?.username);
                setPassword(response.data.data[0]?.Password);
                setserveraddress(response.data.data[0]?.serveraddress);
                setoperator(response.data.data[0]?.operatorname);
                setStatus(response.data.data[0]?.webphoneconfigactive);
                setLoading(false);
            }
        });
    }

  
   
    
    

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/AssignWebphoneConfigList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View Admin</h5>
                        </div> 
                        <div className="rounded table-responsive">

                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">
                                           
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">username <span className='mandatory ms-1'>*</span></label>
                                            <input type="username" className="form-control" id="username" value={username} onChange={(e) => setusername(e.target.value)} disabled/>
                                           
                                        </div>
                                        
                                        <div className="col-lg-4">
                                            <label htmlFor="Password" className="col-form-label">Password <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="Password" value={Password} onChange={(e) => setPassword(e.target.value)} disabled/>
                                          
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">Server Address <span className='mandatory ms-1'>*</span></label>
                                            <input type="serveraddress" className="form-control" id="serveraddress"  value={serveraddress} onChange={(e) => setserveraddress(e.target.value)} disabled />
                                           
                                        </div>
                                        <div className="col-lg-4">
                                                <label htmlFor="operatorName" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                                <input type="serveraddress" className="form-control" id="serveraddress"  value={operator}  disabled />
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="status" className="col-form-label">Status </label>
                                                <input type="text" className="form-control" id="status" value={status == 1 || status == "Active"? "Active" :"Inactive"} onChange={(e) => setStatus(e.target.value)} disabled/>
                                            </div>
                                           
                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-close-btn "  onClick={() => navigate('/AssignWebphoneConfigList')}>Close</button>
                                   
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    );
};
export default AssignWebphoneConfigView;
import React, { useState, useEffect } from 'react';

//import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Loading from '../../Component/Loading/Loading';
import { read, utils } from 'xlsx';
//import ls from 'local-storage';
import checkImg from '../../../src/assets/img/check.png';
import errorImg from '../../../src/assets/img/uncheck.png';


const Uploadxl = (props) => {
    // const AdminId = ls.get("AdminId");
    //  const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [CompanyName, setCompanyName] = useState("");
    const [ListName, setListName] = useState("");
    const [SelectedCompanyName, setSelectedCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState("");
    const [Data, setData] = useState("");

    const [OperatorName, setOperatorName] = useState("");
    const [SelectedOperatorName, setSelectedOperatorName] = useState("");
    const [SelectedListName, setSelectedListName] = useState("");
    const [/* OperatorId */, setOperatorId] = useState("");
    const [/* ListId */, setListId] = useState("");
    const [CompanyNameError, setCompanyNameError] = useState("");
    const [UploadError, setUploadError] = useState("");
    // const [ListNameError, setListNameError] = useState("");
    const [toggleZipcodePopupOpen, setToggleZipcodePopupOpen] = useState(true);
    const [togglePopupOpen, setTogglePopupOpen] = useState(false);
    const [alertimg, setAlertImg] = useState("");

    const [popupTitle, /* setPopupTitle */] = useState("");
    const [popupMsg, /* setPopupMsg */] = useState("");
    const [popupType, /* setPopupType */] = useState("");
    const [popupActionType, /* setPopupActionType */] = useState("");
    const [popupActionValue, /* setPopupActionValue */] = useState("");

    const [alertmessage, setAlertMessage] = useState("");
    const [alerttitle, setAlertTitle] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [loading, setLoading] = useState(true)
  

    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);


    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    // const [isOpen, setIsOpen] = useState(false);
    // const recallfunction = (data) => {
    //     // console.log('reCalling==', data);
    //     setIsOpen(data);
    // };

    useEffect(() => {
        handleCompanyName();
    }, [])

    const handleCompanyName = () => {

        API.post("companyListview/condition").then(response => {
            setCompanyName(response.data.data);
            setLoading(false);
        });
    }

    const handleListName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }

        API.post("customerlistassgin/condition", request).then(response => {
            setListName(response.data.data);
        });
    }

    //operator dropdown service 
    // useEffect(() => {
    //     handleOperatorName();

    // }, [])

    const handleOperatorName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }

        API.post("operatorbycompanyid/condition", request).then(response => {
            setOperatorName(response.data.data);
            setLoading(false);
        });
    }



    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');

    //   const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setFileName(file.name);
    //     setSelectedFile(event.target.files[0]);
    //   };

    console.log(selectedFile);



    const handleCompanyId = (e) => {
        setCompanyId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedCompanyName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        handleOperatorName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
        handleListName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
    };

    const handleOperatorId = (e) => {
        setOperatorId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedOperatorName(
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
    };

    const handleListId = (e) => {
        setListId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedListName(
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    };

    const handleImport = ($event) => {
        console.log("handleImport inside");
    
        const files = $event.target.files;
        setFileName(files[0].name);
        setSelectedFile(files[0]);
    
        if (files.length) {
            setUploadError("");
            const file = files[0];
            console.log(file, 'sheets1');
            const reader = new FileReader();
            console.log(reader, 'sheets2');
            reader.onload = (event) => {
                const binaryData = event.target.result;
                const workbook = read(binaryData, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
    
                const sheetData = utils.sheet_to_json(worksheet, { header: 1 }); // Include header row
                console.log(sheetData, 'sheetData');
    
                const columnNames = ['first_name', 'last_name', 'vehicle', 'version', 'Phone', 'source', 'email', 'color']; // Header row
    
                const validNumbers = sheetData
                    .filter(innerArray => innerArray[4] && String(innerArray[4]).trim() !== "") // Ensure phone number is not empty
                    .filter(innerArray => String(innerArray[4]).match(/^\d{3}-\d{3}-\d{4}$/) && !/[a-zA-Z]/.test(innerArray[4])) // Validate phone number format
                    .map(innerArray => {
                        const normalizedPhoneNumber = String(innerArray[4]).replace(/[-]/g, ''); // Remove dashes from phone number
                        console.log("namedArray2", normalizedPhoneNumber);
    
                        return {
                            first_name: innerArray[0],
                            last_name: innerArray[1],
                            vehicle: innerArray[2],
                            version: innerArray[3],
                            source: innerArray[5],
                            email: innerArray[6],
                            color: innerArray[7],
                            [columnNames[4]]: normalizedPhoneNumber, // Store normalized phone number
                        };
                    });
                console.log("namedArray3", validNumbers);
    
                setData(validNumbers);
            };
            reader.readAsArrayBuffer(file);
        }
    };
    
    


    const handleUpload = () => {

        setCompanyNameError("");
        setUploadError("");
        // setListNameError("");

        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }
        if (!selectedFile) {
            setUploadError("Upload Xl is required");
            return;
        }

      




        // if (!ListId) {
        //     setListNameError("List Name is required");
        //     return;
        // }
        setToggleZipcodePopupOpen(false)
        setTogglePopupOpen(true)
    }

    const handleSubmit = () => {
        setLoading(true);
        setIsSaveButtonClicked(true);
        console.log(Data.length, "Datalength");
        if (Data.length == 0) {
            setTogglePopupOpen(false);
            setLoading(false);
            setAlertTitle("Upload Error");
            setAlertImg(errorImg);
            setAlertMessage("No valid phone numbers found in the uploaded file.");

        }
        else {


            const currentDate = new Date();
            const year = String(currentDate.getFullYear()); // Get the last 2 digits of the year
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure a leading zero for single-digit months
            const day = String(currentDate.getDate()).padStart(2, '0'); // Ensure a leading zero for single-digit days
            const formattedDate = `${year}-${month}-${day}`;

            let request = {
                customerlist: Data,
                status: "Active",
                company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
                operator_id: !SelectedOperatorName ? "" : SelectedOperatorName.operator_id,
                customer_list_id: !SelectedListName ? 0 : SelectedListName.customer_list_id,
                assign_date: formattedDate,
                active: 1,
                createdBy: userData.admin_id,
                updatedBy: userData.admin_id
            }

            API.post('newcustomerAdd/add', request).then((response) => {
                console.log("response", response);
                if (response.data.success == true) {
                    setLoading(false);
                    setTogglePopupOpen(false);

                    setAlertTitle("Add New Customer");
                    setAlertMessage("Customer Created Succesfully");
                    setAlertImg(checkImg);
                    props.reCallFunction();

                    // setLoading(true)
                } else {
                    setLoading(false);
                    setAlertTitle("Add New Customer");
                    setAlertImg(errorImg);
                    setAlertMessage(response.data.error.err);

                }


            });
        }
    }

    return (




        <div>
            <div>


                {/* {loading ? <Loading /> : */}

                {toggleZipcodePopupOpen ?
                    (<>
                        <div className="main-contents">
                            <div className="modal-bodys">

                                {loading ? <Loading /> :

                                    <div className="row">

                                        <div className="back-heading">
                                            <h5 className="heading-text">Upload Customer</h5>
                                        </div>

                                        <div className=" col-md-6 col-lg-6">
                                            {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                            <label htmlFor="customerName" className="form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleCompanyId}

                                            >

                                                <option style={{ display: "none" }}>Select CompanyName</option>
                                                {CompanyName?.length > 0 && (
                                                    <>
                                                        {CompanyName.map((CompanyNameID) => (
                                                            <option
                                                                key={CompanyNameID.company_id}
                                                                value={CompanyNameID.company_id}
                                                            >
                                                                {CompanyNameID.company_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                            <p className="form-input-error">{CompanyNameError}</p>
                                        </div>
                                        <div className='col-lg-6 upload'>
                                            <form>
                                                <div className="custom-file me-2">


                                                    <label className="custom-file-label" htmlFor="inputGroupFiles"><i className="fa-solid fa-cloud-arrow-up me-1"></i>Upload XL</label>
                                                    <input type="file" name="file" className="custom-file-input" id="inputGroupFiles" required onChange={handleImport}
                                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />

                                                </div>
                                                <p> {fileName}</p>
                                                <p className="form-input-error">{UploadError}</p>
                                            </form>
                                        </div>

                                        {SelectedCompanyName === "" ?
                                            <div className=" col-md-6 col-lg-6">
                                                <label htmlFor="customerName" className="form-label">Operator Name </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    id="Sale"
                                                    disabled
                                                >
                                                    <option style={{ display: "none" }}>Select OperatorName</option>
                                                </select>
                                            </div>
                                            :
                                            <div className=" col-md-6 col-lg-6">
                                                <label htmlFor="customerName" className="form-label">Operator Name </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    id="Sale"
                                                    onChange={handleOperatorId}

                                                >
                                                    <option style={{ display: "none" }}>select OperatorName</option>
                                                    {OperatorName?.length > 0 && (
                                                        <>
                                                            {OperatorName.map((OperatorNameID) => (
                                                                <option
                                                                    key={OperatorNameID.operator_id}
                                                                    value={OperatorNameID.operator_id}
                                                                >
                                                                    {OperatorNameID.operatorname}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                        }

                                        <div className=" col-md-6 col-lg-6">
                                            <label htmlFor="customerName" className="form-label">List Name {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                            {/* <p className="form-input-error">{ListNameError}</p> */}
                                        </div>



                                    </div>
                                }

                                <div className="modal-footer">

                                    <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={props.toggle}>Close</button>

                                    <button type="button" className="btn form-submit-btn" onClick={handleUpload} >Submit</button>




                                </div>


                            </div>
                        </div>
                    </>) :
                    togglePopupOpen ?
                        <>

                            <div className="popup-box">
                                {loading ? <Loading /> :
                                    <div className=" recycle">
                                        <div className='successpopup'>

                                            <div className="row">
                                                <div className="back-heading">
                                                    <h5 className="heading-text">Confirmation</h5>
                                                    <p className='Para-text'>Are You Sure, Do you want to Upload the Details</p>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={props.toggle} disabled={isSaveButtonClicked}>Close</button>
                                                <button type="button" className="btn form-submit-btn" onClick={handleSubmit} disabled={isSaveButtonClicked}>Save </button>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>

                        </>
                        :
                        (<div className='uploadpopup'>
                            <div className="Commonfull-icon">
                                <img className={alertimg === checkImg ? "successImg " : "errorImg"} src={alertimg} />
                            </div>
                            <div className="CommonModalbody">
                                <h2>{alerttitle}</h2>
                                <p>{alertmessage}</p>
                            </div>
                            <div className="CommonModalfooter ">
                                <div className="CommonModalfooter session">

                                    <button className="btn btn-smlprimary" onClick={props.toggle}>Ok</button>
                                </div>
                            </div>

                        </div>
                        )}



            </div>
            {
                isOpenPopup && (
                    <CommonPopup
                        handleClose={togglePopup}
                        popupTitle={popupTitle}
                        popupMsg={popupMsg}
                        popupType={popupType}
                        popupActionType={popupActionType}
                        popupActionValue={popupActionValue}
                    // popupActionPath={popupActionPath}
                    />
                )
            }
        </div>



    );
};
export default Uploadxl;
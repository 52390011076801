import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
 import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import moment from 'moment';

const RescheduleEdit = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const CustomerMapId = location.state.Schedulecall;
    const [customerId, setCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [, setSelectedCustomerName] = useState("");
    const [operatorId, setOperatorId] = useState("");
    const [operatorName, setOperatorName] = useState("");
    const [, setSelectedOperatorName] = useState("");
    const [assignDate, setAssignDate] = useState("");
    const [comments, setComments] = useState("");
    const [Time, setTime] = useState("");
    const [operatorcustomermapid, setoperatorcustomermapid] = useState("");

    const [customerNameError, setCustomerNameError] = useState("");
    const [operatorNameError, setOperatorNameError] = useState("");
    const [assignDateError, setAssignDateError] = useState("");
    const [TimeError, setTimeError] = useState("");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        handleCustomerName();
        handleOperatorName();
        CustomersMapView();
    }, [])

    const CustomersMapView = () => {

        let request = {
            schedule_calls_id: CustomerMapId
        }

        API.post("operatorreschedulelistbyid/condition", request).then(response => {
            // setCustomer(response.data.data[0]?.customer_name)
            // setOperator(response.data.data[0]?.operator_name)
            setOperatorId(response.data.data[0]?.operator_id);
            setCustomerId(response.data.data[0]?.customer_id)
            setAssignDate(response.data.data[0]?.assign_date);
            setComments(response.data.data[0]?.comments);
            setTime(response.data.data[0]?.assign_time);
            setoperatorcustomermapid(response.data.data[0]?.operator_customermap_id);
            setTimeout(() => {
                setLoading(false);
            }, 800);
        });
    }

    const handleCustomerName = () => {

        API.post("mapCustomerlist/condition").then(response => {
            setCustomerName(response.data.data);
        });
    }
    const handleCustomerNameId = (e) => {
        setCustomerId(e.target.value);
        const selectedCustomerDetails = customerName.filter(data => data.customer_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);

    }

    const handleOperatorName = () => {

        API.post("mapOperatorlist/condition").then(response => {
            setOperatorName(response.data.data);
        });
    }
    const handleOperatorNameId = (e) => {
        setOperatorId(e.target.value);
        const selectedLangDetails = operatorName.filter(data => data.operator_id == e.target.value)[0]
        setSelectedOperatorName(selectedLangDetails);
    }

    const handleAdd = () => {

        if (!customerId) {
            setCustomerNameError("Customer Name is required");
            return;
        }
        if (!operatorId) {
            setOperatorNameError("Operator Name is required");
            return;
        }
        if (!assignDate) {
            setAssignDateError("Assign Date is required");
            return;
        }
        if (!Time) {
            setTimeError("Assign Time is required");
            return;
        }

        let request = {
            schedule_calls_id: CustomerMapId,
            customer_id: customerId,
            operator_id: operatorId,
            assign_date: assignDate,
            assign_time: Time,
            operator_customermap_id: operatorcustomermapid,
            comments: comments,
            reschedule: "admincallreschedule",
            createdBy: userData.admin_id,
            customer_map_status:"ADMIN",
            updatedBy: userData.admin_id
        };

        console.log("req", request);
        API.post('operatorscheduleedit/update ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Reschedule");
                setPopupMsg("Edit Reschedule Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/Reschedule");
            }
            else {
                togglePopup();
                setPopupTitle("Reschedule");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const inputProps = {
        placeholder: "DD/MM/YYYY",
        value: assignDate
    };

    const yesterday = moment().subtract(1, 'day');
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };
    const registrationDate = (event) => {
        if (event._isAMomentObject == true) {
            setAssignDate(event.format("YYYY-MM-DD"));
          }
    }


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content editreschedule">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/Reschedule')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Edit Reschedule </h5>
                        </div>
                        <div className="rounded table-responsive">
                            <div className="modal-body editres">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row ">
                                            <div className="col-lg-4 ">
                                                <label htmlFor="customerName" className="col-form-label">Customer Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="customerName" value={customerId} onChange={handleCustomerNameId} >
                                                    {/* <option style={{ display: "none" }}>{customerId}</option> */}

                                                    {customerName.length > 0 &&
                                                        <>
                                                            {customerName.map((data) => <option key={data.customer_id} value={data.customer_id} >{data.customer_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{customerNameError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="operatorName" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="operatorName" value={operatorId} onChange={handleOperatorNameId} >
                                                    {/* <option style={{ display: "none" }}>{operatorId}</option> */}
                                                    {operatorName.length > 0 &&
                                                        <>
                                                            {operatorName.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operator_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{operatorNameError}</p>
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                <input type="date" className="form-control" id="assignDate" value={assignDate} onChange={(e) => setAssignDate(e.target.value)} />
                                                <p className="form-input-error" >{assignDateError}</p>
                                            </div> */}
                                            <div className="col-lg-4 form-group datePickerBlock ">
                                            <div className="tbox">
                                                <div className="textbox">
                                                    <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                    <Datetime inputProps={inputProps}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        name="Date"
                                                        id="meeting_date"
                                                        isValidDate={disablePastDt}
                                                        onChange={registrationDate}
                                                    />

                                                    <p className="form-input-error" >{assignDateError}</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="time" className="col-form-label" >Time<span className='mandatory ms-1'>*</span></label>
                                                <input type="time" className="form-control" id="time" value={Time} onChange={(e) => setTime(e.target.value)} />
                                                <p className="form-input-error" >{TimeError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="comments" className="col-form-label" >Comments</label>
                                                <input type="text" className="form-control" id="comment" defaultValue={comments} onChange={(e) => setComments(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>
                                    </form>

                                }
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default RescheduleEdit;
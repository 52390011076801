import React, { useState } from 'react';
import user from '../../assets/img/user.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import API from '../../Services/BaseService';
import ls from 'local-storage';

const Header = (props) => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);

    const [isOpen, setIsOpen] = useState(true);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
        props.recallfunction(isOpen);
    };


    return (
        <>
            <div className="top-navbar">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">

                        <button onClick={toggleOpen} type="button" id="sidebarCollapse" className="d-xl-block d-lg-block d-md-block d-none">
                            <span> <i className="fa-solid fa-bars"></i></span>
                        </button>

                        <div className="collapse navbar-collapse d-lg-block d-xl-block d-sm-none d-md-none d-none header-icon"
                            id="navbarSupportedContent ">
                            <ul className="nav navbar-nav ml-auto">

                                <li className="nav-item">
                                    <a className="nav-link" >Welcome {userData.first_name}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"><img src={user} /></a>
                                </li>
                                <li>
                                <a className="mainbutton" onClick={() => { navigate('/'); ls.set("languageId", ""); ls.set("userDetails", "");  localStorage.clear(); }}
                                    >Logout </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};
export default Header;
import React, { useState} from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';



const ConfigAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [username, setusername] = useState("");
    const [Password, setPassword] = useState("");
    const [serveraddress, setserveraddress] = useState("");
   



    // const [operatorID, setoperatorID] = useState("");
    // const [operator, setoperator] = useState("");
    // const [Selectedoperator, setSelectedoperator] = useState("");


    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    // useEffect(() => {
    //     OperatorList();
    // }, [])

    // const OperatorList = () => {
    //     API.post('operatorlist/condition',).then((response) => {
    //         if (response.data.success == true) {

    //             setoperatorID(response.data.data);

    //         }
    //     });
    // }

    const handleAdd = () => {
       
        
        let request = {
           // operator_id:operator,
            username: username,
            password: Password,
            serveraddress: serveraddress,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('webphoneconfig/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add New Config");
                setPopupMsg("Config Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/Config");
            }
            else {
                // setTimeout(() => {
              
                //   }, 100);
                togglePopup();
                setPopupTitle("Add New Admin");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

   
    // const handleoperatorId = (e) => {
    //     setoperator(e.target.value);
    //     console.log("=========check", e.target.value);
    //     setSelectedoperator(
    //         operatorID.filter((data) => data.operator_id == e.target.value)[0]
    //     );
      
    // };

    // console.log(Selectedoperator);

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                   
                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/Config')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New Config</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                       

                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">User Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="username" className="form-control" id="username" onChange={(e) => setusername(e.target.value)} />
                                           
                                        </div>
                                        
                                        <div className="col-lg-4">
                                            <label htmlFor="Password" className="col-form-label">Password <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="Password" onChange={(e) => setPassword(e.target.value)} />
                                          
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">Server Address <span className='mandatory ms-1'>*</span></label>
                                            <input type="serveraddress" className="form-control" id="serveraddress" onChange={(e) => setserveraddress(e.target.value)} />
                                           
                                        </div>
                                        {/* <div className=" col-md-6 col-lg-4 ">
                                        <label className="form-label">
                                        Operator
                                    </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="Sale"
                                            onChange={handleoperatorId}
                                            defaultValue={operator}
                                        >
                                            <option style={{ display: "none" }} >Operator Name</option>
                                            {operatorID?.length > 0 && (
                                                <>
                                                    {operatorID.map((operatorID) => (
                                                        <option
                                                            key={operatorID.operator_id}
                                                            value={operatorID.operator_id}
                                                        >
                                                            {operatorID.operatorname}
                                                        </option>
                                                    ))}
                                                </>
                                            )}
                                        </select>
                                    </div> */}
                                        
                                       
                                        
                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ConfigAdd;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import FileBase64 from 'react-file-base64';
import adduser from '../../assets/img/adduser.jpg';
import PhoneInputs from 'react-phone-input-2'

const OperatorAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pNumber, setPNumber] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [/* emailError */, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [pNumberError, setPNumberError] = useState("");

    const [CompanyName, setCompanyName] = useState("");
    const [CompanyId, setCompanyId] = useState("");
    const [CompanyNameError, setCompanyNameError] = useState("");
    const [selectedCustomerName, setSelectedCustomerName] = useState("");

    const [image,] = useState("");
    const [doc, setDoc] = useState("");
    const [type, setType] = useState("");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    useEffect(() => {
        handleCompanyName();
      
    }, [])

    const handleCompanyName = () => {

        API.post("mapcompanyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const handleCompany = (e) => {
        setCompanyId(e.target.value);
        const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);

    }

    const handleAdd = () => {
        setFirstNameError("");
        setLastNameError("");
        // setEmailError("");
        setUserNameError("");
        setPasswordError("");
        setPNumberError("");
        setCompanyNameError("")

        if (!firstName) {
            setFirstNameError("First Name is required");
            return;
        }
        if (!lastName) {
            setLastNameError("Last Name is required");
            return;
        }
        // if (!email) {
        //     setEmailError("Email is required");
        //     return;
        // }
        // else if (email && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email)) {
        //     setEmailError('Must match the Username format');
        //     return;
        // }
        if (!userName) {
            setUserNameError("User Name is required");
            return;
        }
       
        if (!password) {
            setPasswordError("Password is required");
            return;
        }
        else if (password.length < 8) {
            setPasswordError("Password must have 8 characters");
            return;
        }
        if (!pNumber) {
            setPNumberError("Phone Number is required");
            return;
        }
        else if (pNumber.length < 10) {
            setPNumberError("Phone Number must have 10 digits");
            return;
        }
        else if (pNumber.length > 10) {
            setPNumberError("Phone Number accept only 10 digits");
            return;
        }
        
        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }

        let request = {
            operator_image: doc === "" ? "" : doc.length > 0 ? doc : [doc],
            first_name: firstName,
            last_name: lastName,
            user_name: userName,
            email: email,
            Password: password,
            phone: pNumber,
            company_id: selectedCustomerName.company_id,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('operatorAdd/add ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Add New Operator");
                setPopupMsg("Operator Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/operator");
            }
            else {
                setEmailError(response.data.error.err);
                togglePopup();
                setPopupTitle("Add New Operator");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const getFiles = (file) => {
        console.log("================>", file.type)
        setType("")
        if (file.type.includes("jpg") || file.type.includes("jpeg") || file.type.includes("png")) {
            setDoc(file);
        } else {
            setType("0");
        }
    }

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading ">
                            <div className="back-btn" onClick={() => navigate('/operator')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New Operator</h5>
                        </div>

                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12 ">

                                            <div className="user-upload-btn-wrapper">
                                                {(image === "" || image == null || image == undefined) && doc === "" ? <img alt="" src={adduser} /> :
                                                    doc === "" ? <img alt="" src={image} /> :
                                                        <img alt="" src={doc.base64} />}
                                                <span className="proCamera"></span>
                                                <FileBase64 onDone={getFiles} type="hidden" />

                                                {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="first-name" className="col-form-label">First Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="first-name" onChange={(e) => setFirstName(e.target.value)} />
                                            <p className="form-input-error">{firstNameError}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="last-name" className="col-form-label">Last Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="last-name" onChange={(e) => setLastName(e.target.value)} />
                                            <p className="form-input-error">{lastNameError}</p>
                                        </div>
                                        
                                        <div className="col-lg-4">
                                            <label htmlFor="email" className="col-form-label">Email {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} />
                                            {/* <p className="form-input-error">{emailError}</p> */}
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="username" className="col-form-label">User Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="username" onChange={(e) => setUserName(e.target.value)} />
                                            <p className="form-input-error">{userNameError}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="password" className="col-form-label">Password <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} />
                                            <p className="form-input-error">{passwordError}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            {/* <label htmlFor="phone-number" className="col-form-label">Phone Number <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="phone-number" onChange={(e) => setPNumber(e.target.value)} /> */}
                                            <label htmlFor="phone-number" className="col-form-label required"> Phone</label>    
                                            <PhoneInputs
                                                class="form-control mt-5"
                                                country={'us'}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                onlyCountries={['us']}
                                                onChange={(e) => setPNumber(e)}
                                                placeholder=""
                                            ></PhoneInputs>
                                            <p className="form-input-error">{pNumberError}</p>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <select className="form-select" id="customerName" onChange={handleCompany} >
                                                <option style={{ display: "none" }}>Select CompanyName</option>
                                                {CompanyName.length > 0 &&
                                                    <>
                                                        {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{CompanyNameError}</p>
                                        </div>
                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default OperatorAdd;
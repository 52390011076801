
import React from "react";
import { Navigate,Outlet } from "react-router-dom";
import ls from 'local-storage';


const PrivateRoute = () => {
 // let auth = {'token':false}
 let auth = ls.get('token');
  return  (
    
    auth ? <Outlet/> : <Navigate to ="/"/>
  ) 

  
}

export default PrivateRoute;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
//import Loading from '../../Component/Loading/Loading';

const DashBoard = () => {

//const [dashBoardInfo, setDashBoardInfo] = useState("");
const [countInfo, setCountInfo] = useState("");
//const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        //historyList();
        dashboardCount();
    }, [])

    // const historyList = () => {
    //     API.post('callHistoryList/condition', ).then((response) => {
    //         if (response.data.success == true) {
    //             setDashBoardInfo(response.data.data);
    //             setLoading(false);
    //         }
          
    //     });
    // }

    const dashboardCount = () => {
        API.post('dashboardcount/condition', ).then((response) => {
            if (response.data.success == true) {
                setCountInfo(response.data.data[0]);
            }
          
        });
    }



    return (
        <>
            <div className="wrapper">
                <SideBar sideBarOpen={isOpen} />
                <div id="content" className={!isOpen ? '' : 'active'}>
                    <Header recallfunction={recallfunction} />
                    <div className="main-content-dash">
                        <section className="dashboard-sec">
                            <div className="row">
                                <h4 className="common-title">dashboard</h4>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Customers</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{countInfo?.totalcustomercount}</h6>
                                                {/* <p>Since last one hour</p> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color1">
                                                <i className="fa-solid fa-users"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Operators</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{countInfo?.totaloperatorecount}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color2">
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Last call</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>2</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color3">
                                            <i className="fa-solid fa-headset"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Calls</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{countInfo?.totalcallscount}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color4">
                                            <i className="fa-solid fa-phone"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Reports</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                            <h6>{countInfo?.totaloperatorreportcount}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color5">
                                            <i className="fa-solid fa-bug"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>
                        <div className="row">
                            {/* <div className="common-heading-sec col-lg-12">
                                <h5 className="me-auto">Recent Calls</h5>
                            </div> */}
                            {/* {loading ? <Loading /> :
                                <div className="rounded table-responsive">
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Operator Name</th>
                                                <th>Customer Name</th>
                                                <th>Phone No</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashBoardInfo.length > 0 ? dashBoardInfo.slice(0, 5).map((dashBoardInfo, index) =>
                                                <tr key={index}>
                                                   <td>{dashBoardInfo.call_id}</td>
                                                   <td>{dashBoardInfo.emp_name}</td>
                                                   <td>{dashBoardInfo.cust_name}</td>
                                                   <td>{dashBoardInfo.phone}</td>
                                                   <td>{dashBoardInfo.date}</td>
                                                   <td>{dashBoardInfo.time}</td>
                                                   <td>{dashBoardInfo.comments}</td>
                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            } */}

                        </div>
                    </div>
                </div>
            </div>



        </>
    );
};
export default DashBoard;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
 import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import moment from 'moment';

const CustomersMapEdit = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const CustomerMapId = location.state.CustomerMapId;
    const [customerId, setCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [, setSelectedCustomerName] = useState("");
    const [operatorId, setOperatorId] = useState("");
    const [operatorName, setOperatorName] = useState("");
    const [, setSelectedOperatorName] = useState("");
    const [assignDate, setAssignDate] = useState("");
    const [comments, setComments] = useState("");
    const [Time, setTime] = useState("");
    const [Time1, setTime1] = useState("");
    const [date1, setdate1] = useState("");
    const [assigncreatedtimes, setassigncreatedtimes] = useState("");
    const [operatorcustomermapid, setoperatorcustomermapid] = useState("");
    console.log(Time1, "yyyyTime1");
    const [TimeFlag, setTimeFlag] = useState(false);

    const [customerNameError, setCustomerNameError] = useState("");
    const [operatorNameError, setOperatorNameError] = useState("");
    const [assignDateError, setAssignDateError] = useState("");
    const [TimeError, setTimeError] = useState("");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        handleCustomerName();
        handleOperatorName();
        CustomersMapView();
    }, [])

    const CustomersMapView = () => {

        let request = {
            customermap_id: CustomerMapId
        }

        API.post("customerMapview/condition", request).then(response => {
            // setCustomer(response.data.data[0]?.customer_name)
            // setOperator(response.data.data[0]?.operator_name)
            setOperatorId(response.data.data[0]?.operator_id);
            setCustomerId(response.data.data[0]?.customer_id)
            setAssignDate(response.data.data[0]?.assign_date);
            setComments(response.data.data[0]?.comments);
            setTime(response.data.data[0]?.assign_time);
            setassigncreatedtimes(response.data.data[0]?.assigncreatedtimes);
            setoperatorcustomermapid(response.data.data[0]?.operator_customermap_id);
            setTimeout(() => {
                setLoading(false);
            }, 800);
        });
    }

    const handleCustomerName = () => {

        API.post("mapCustomerlist/condition").then(response => {
            setCustomerName(response.data.data);
        });
    }
    const handleCustomerNameId = (e) => {
        setCustomerId(e.target.value);
        const selectedCustomerDetails = customerName.filter(data => data.customer_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);

    }

    const handleOperatorName = () => {

        API.post("mapOperatorlist/condition").then(response => {
            setOperatorName(response.data.data);
        });
    }
    const handleOperatorNameId = (e) => {
        setOperatorId(e.target.value);
        const selectedLangDetails = operatorName.filter(data => data.operator_id == e.target.value)[0]
        setSelectedOperatorName(selectedLangDetails);
    }

    const handleAdd = () => {

        if (!customerId) {
            setCustomerNameError("Customer Name is required");
            return;
        }
        if (!operatorId) {
            setOperatorNameError("Operator Name is required");
            return;
        }
        if (!assignDate) {
            setAssignDateError("Assign Date is required");
            return;
        }
        if (!Time) {
            setTimeError("Assign Time is required");
            return;
        }

        let request = {
            customermap_id: CustomerMapId,
            customer_id: customerId,
            operator_id: operatorId,
            assign_date: date1 !== "" ? date1 : assignDate,
            assign_time: Time1 !== "" ? Time1 : Time,
            operator_customermap_id: operatorcustomermapid,
            comments: comments,
            reschedule: "admincallreschedule",
            createdBy: userData.admin_id,
            customer_map_status:"ADMIN",
            updatedBy: userData.admin_id
        };

        console.log("req", request);
        API.post('customerMapedit/update ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Assign Customer");
                setPopupMsg("Edit Assigned Customer Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/customersMap");
            }
            else {
                togglePopup();
                setPopupTitle("Assign Customer");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const inputProps = {
        placeholder: "DD/MM/YYYY",
        value: assignDate
    };

    const yesterday = moment().subtract(1, 'day');
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };
    const registrationDate = (event) => {
        if (event._isAMomentObject == true) {
            setAssignDate(event.format("YYYY-MM-DD"));
          }
    }

    // var utc = new Date(assigncreatedtimes);
    // var offset = utc.getTimezoneOffset();
    // var values = new Date(utc.getTime() + offset * 60000);
    // values = moment(values).format('hh:mm')
    // console.log(utc, "time1");
    // // console.log(local,"time");
    // console.log(values, "timet");





    function formatTime() {
        console.log(assigncreatedtimes, "yyyy1");
        var local = new Date(assigncreatedtimes);

        //var local = new Date("2023-05-22 08:21:11");
        var offset = local.getTimezoneOffset();
        var value = new Date(local.getTime() - offset * 60000);
        value = moment(value).format('MMMM D, YYYY hh:mm A')
        const times = moment(value).format('hh:mm')
        // let utc2222 = utc1
        console.log(value, times, "yyyytime");
        return times;
    }


    function timezone(value) {
        console.log(value, "yyyy1");
        // setTimeFlag(true);


        // setTime(value);


        // const localTime = new Date(assignDate+" "+value);
        // console.log(localTime,"utcTimeString1");

        // const utcTime1 = new Date(localTime).toUTCString();
        // const times = moment(utcTime1).format('hh:mm')


        // console.log(utcTime1,times,"utcTimeString3");

        // setTime1(utcTime1);



        console.log(value, "yyyy1");
        setTimeFlag(true);

        // let utc1 = ""

        setTime(value);


        // let localTime = new Date(assignDate+" "+value);
        // localTime = moment(localTime).format('yyyy-mm-d HH:mm:ss')
        // console.log(localTime,"utcTimeString3lt");
        let utcTime1 = new Date(assignDate + " " + value).toUTCString();


        console.log(utcTime1, "utcTime1");
        //  const date = new Date(utcTime1);
        //  const year = date.getFullYear();
        //  const month = (date.getMonth() + 1).toString().padStart(2, '0');
        //  const day = date.getDate().toString().padStart(2, '0');
        //  const formattedDate = `${year}-${month}-${day}`;

        //  console.log(formattedDate, "utcTime12");
        //  value = moment(utcTime1).format('YYYY-MM-DD')
        // console.log(value,"utc");
        const dateObj = new Date(utcTime1);
        const formattedDate = dateObj.toISOString().split("T")[0];
        console.log(formattedDate, "utcTime12");
        const timeString = utcTime1.split(' ')[4].slice(0, 5);

        console.log(timeString, "utcTimeString232323");



        setdate1(formattedDate)
        setTime1(timeString);



    }


    // let local = ""

    // value = value.replace(" ","T")
    // value = value+"Z";
    // console.log(value, "yyyy1");
    //  local = new Date(value);
    // value =local.toLocaleString();

    //  value = moment(value).format('MMMM D, YYYY hh:mm A')

    //  console.log(value, "yyyy");

    //  local = new Date(value);

    //  //var local = new Date("2023-05-22 08:21:11");
    //  offset = local.getTimezoneOffset();
    //  value = new Date(local.getTime() - offset * 60000);
    //  value = moment(value).format('MMMM D, YYYY hh:mm A')




    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/customersMap')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Edit Assign Customer</h5>
                        </div>
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row ">
                                            <div className="col-lg-4 ">
                                                <label htmlFor="customerName" className="col-form-label">Customer Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="customerName" value={customerId} onChange={handleCustomerNameId} >
                                                    {/* <option style={{ display: "none" }}>{customerId}</option> */}

                                                    {customerName.length > 0 &&
                                                        <>
                                                            {customerName.map((data) => <option key={data.customer_id} value={data.customer_id} >{data.customer_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{customerNameError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="operatorName" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="operatorName" value={operatorId} onChange={handleOperatorNameId} >
                                                    {/* <option style={{ display: "none" }}>{operatorId}</option> */}
                                                    {operatorName.length > 0 &&
                                                        <>
                                                            {operatorName.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operator_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{operatorNameError}</p>
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                <input type="date" className="form-control" id="assignDate" value={assignDate} onChange={(e) => setAssignDate(e.target.value)} />
                                                <p className="form-input-error" >{assignDateError}</p>
                                            </div> */}
                                            <div className="col-lg-4 form-group datePickerBlock ">
                                            <div className="tbox">
                                                <div className="textbox">
                                                    <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                    <Datetime inputProps={inputProps}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        name="Date"
                                                        id="meeting_date"
                                                        isValidDate={disablePastDt}
                                                        onChange={registrationDate}
                                                    />

                                                    <p className="form-input-error" >{assignDateError}</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="time" className="col-form-label" >Time<span className='mandatory ms-1'>*</span></label>
                                                <input type="time" className="form-control" id="time" value={TimeFlag === true ? Time : formatTime()} onChange={(e) => timezone(e.target.value)} />
                                                <p className="form-input-error" >{TimeError}</p>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="comments" className="col-form-label" >Comments</label>
                                                <input type="text" className="form-control" id="comment" defaultValue={comments} onChange={(e) => setComments(e.target.value)} />
                                            </div>
                                        </div>
                                    </form>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default CustomersMapEdit;
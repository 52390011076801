import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';

import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';


const ListView = () => {
    const AdminId = ls.get("customer_list_id");
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);


    const [ListName, setListName] = useState("");
    const [ListId, setListId] = useState("");
    const [Description, setDescription] = useState("");
    const [Status, setStatus] = useState("");
    const [CompanyName, setCompanyName] = useState("");

    // console.log("calllimit",calllimit);

    const [loading, setLoading] = useState(true)



    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    // useEffect(() => {
    //     OperatorList();
    // }, [])

    // const OperatorList = () => {
    //     API.post('mapOperatorlist/condition',).then((response) => {


    //             setoperatorID(response.data.data);


    //     });
    // }

    useEffect(() => {
        Configview();
    }, [])

    const Configview = () => {
        let request = {
            customer_list_id: AdminId,
        };
        API.post('customerlistnameid/condition ', request).then((response) => {
            if (response.data.success == true) {

                setListName(response.data.data[0].list_name);
                setListId(response.data.data[0].customer_list_id);
                setDescription(response.data.data[0].description);
                setStatus(response.data.data[0].active);
                setCompanyName(response.data.data[0].company_name);
                setLoading(false);
            }
        });
    }



    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/ListName')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View List Name</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">

                                            <div className="col-lg-4">

                                                <label htmlFor="ListId" className="col-form-label">ListId </label>
                                                <input type="text" className="form-control" id="ListId" value={ListId} disabled />
                                            </div>

                                            <div className="col-lg-4">

                                                <label htmlFor="CompanyName" className="col-form-label">Company Name </label>
                                                <input type="text" className="form-control" id="CompanyName" value={CompanyName} disabled />
                                            </div>

                                            <div className="col-lg-4">
                                                <label htmlFor="ListName" className="col-form-label">List Name {/* <span className='mandatory ms-1'>*</span> */}</label>
                                                <input type="text" className="form-control" id="ListName" value={ListName} disabled />

                                            </div>
                                            <div className="col-lg-4">

                                                <label htmlFor="Description" className="col-form-label">Description </label>
                                                <input type="text" className="form-control" id="Description" value={Description} disabled />
                                            </div>

                                            <div className="col-lg-4">

                                                <label htmlFor="Status" className="col-form-label">Status </label>
                                                <input type="text" className="form-control" id="Status" value={Status == "Active"||Status == 1 ? "Active" :"Inactive"}  disabled />
                                            </div>

                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-close-btn" onClick={() => navigate('/ListName')}>Close</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};
export default ListView;
import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';


const ConfigEdit = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    // const [username, setusername] = useState("");
    // const [Password, setPassword] = useState("");
    // const [serveraddress, setserveraddress] = useState("");
    const AdminId = ls.get("webphone_config_id");



    // const [operatorID, setoperatorID] = useState("");
    // console.log(operatorID,"operatorID");
    const [operatorID, setoperatorID] = useState("");
    const [operator, setoperator] = useState("");

    const [Password, setPassword] = useState("");
    const [serveraddress, setserveraddress] = useState("");
    const [assignID, setassignID] = useState("");
    const [Assign, setAssign] = useState("");
    const [status, setStatus] = useState("");
    // const [Selectedoperator, setSelectedoperator] = useState("");

    console.log(operator, 'op');
    const [Selectedoperator, setSelectedoperator] = useState("");
    console.log(Selectedoperator, "Selectedoperator");
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [loading, setLoading] = useState(true)


    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    // useEffect(() => {
    //     OperatorList();
    // }, [])

    // const OperatorList = () => {
    //     API.post('mapOperatorlist/condition',).then((response) => {


    //             setoperatorID(response.data.data);


    //     });
    // }
    useEffect(() => {
        OperatorList();
    }, [])

    useEffect(() => {
        AssignList();
    }, [])

    const OperatorList = () => {
        API.post('mapOperatorlist/condition',).then((response) => {
            if (response.data.success == true) {
                setoperatorID(response.data.data);

            }
        });
    }

    const AssignList = () => {
        API.post('mapassignwebphoneconfig/condition',).then((response) => {
            if (response.data.success == true) {
                console.log(response.data.data, "checkassign");
                setassignID(response.data.data);
            }
        });
    }

    useEffect(() => {
        Configview();
    }, [])

    const Configview = () => {
        let request = {
            assign_webphone_config_id: AdminId,
        };
        API.post('assignwebphoneconfigListbid/condition ', request).then((response) => {
            if (response.data.success == true) {
                setAssign(response.data.data[0]?.webphone_config_id);
                setPassword(response.data.data[0]?.webphoneconfigpassword);
                setserveraddress(response.data.data[0]?.serveraddress);
                setoperator(response.data.data[0]?.operator_id)
                setStatus(response.data.data[0]?.webphoneconfigactive);
                setLoading(false);
            }
        });
    }

    const handleUpdate = () => {


        let request = {
            operator_id: operator,
            webphone_config_id: Assign,
            assign_webphone_config_id: AdminId,
            active: status,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('assignwebphoneconfig/update', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Edit Config");
                setPopupMsg("Config Updated  Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/AssignWebphoneConfigList");
            }
            else {
                // setTimeout(() => {

                //   }, 100);
                togglePopup();
                setPopupTitle("Edit  Config");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const handleoperatorId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);


    };
    const handleAssignId = (e) => {
        setAssign(e.target.value);
        console.log("=========checkasign", e.target.value);

        setSelectedoperator(
            assignID.filter((data) => data.webphone_config_id == e.target.value)[0]
        );

    };

    // const handleOperatorNameId = (e) => {
    //     setoperator(e.target.value);
    //     const selectedLangDetails = operatorID.filter(data => data.operator_id == e.target.value)[0]
    //     setSelectedoperator(selectedLangDetails);
    // }
    //   console.log(Selectedoperator);
    // var dropdown = document.getElementById('Assign');
    // var field = document.getElementById('Password');

    // // Add event listener to the dropdown
    // dropdown.addEventListener('change', function() {
    //   // Update the field value based on the selected option
    //   field.value = dropdown.value;
    //   console.log(field.value,"drop");
    // });

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/AssignWebphoneConfigList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Assign WebphoneConfig Edit</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">



                                            <div className="col-lg-4">
                                                <label htmlFor="operator" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="operator" value={operator} onChange={handleoperatorId} >
                                                    {/* <option style={{ display: "none" }}>{operatorId}</option> */}
                                                    {operatorID.length > 0 &&
                                                        <>
                                                            {operatorID.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operator_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-4">
                                                <label htmlFor="Assign" className="col-form-label"> Assign Config <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="Assign" value={Assign} onChange={handleAssignId} >
                                                    {/* <option style={{ display: "none" }}>{operatorId}</option> */}
                                                    {assignID.length > 0 &&
                                                        <>
                                                            {assignID.map((data) => <option key={data.webphone_config_id} value={data.webphone_config_id} >{data.webphoneconfigusername}</option>)}
                                                        </>
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-4">
                                                <label htmlFor="Password" className="col-form-label">Password <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="Password" value={Selectedoperator ? Selectedoperator.webphoneconfigpassword : Password} disabled />

                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="first-name" className="col-form-label">Server Address <span className='mandatory ms-1'>*</span></label>
                                                <input type="serveraddress" className="form-control" id="serveraddress" value={Selectedoperator ? Selectedoperator.serveraddress : serveraddress} disabled />

                                            </div>
                                            <div className="col-lg-4 ">
                                                <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option selected style={{ display: "none" }}  >{status == "Active"||status == 1 ? "Active" :"Inactive"}</option>
                                                    <option value="1">Active</option>
                                                    <option value="4">Inactive</option>
                                                </select>
                                                {/* <p className="form-input-error">{StatusError}</p> */}
                                            </div>



                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleUpdate}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ConfigEdit;
import React, { useState, } from 'react';
import API from '../../Services/BaseService';
import { useDispatch} from 'react-redux';
import LoginAction from './LoginAction';
import ls from 'local-storage';
import { useNavigate } from 'react-router-dom';
// import LoginpageLogo from '../../assets/img/loginpage_logo.png';
import loginimg from '../../assets/img/login_img.jpg';
// import loginlogo from '../../assets/img/login_logo.png';
import Logo_final from '../../assets/img/fmlogo.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeslash = <i className="fa-solid fa-eye-slash"></i>
    console.log("slash", eyeslash);
    const [UserName, setUserName] = useState('');
    const [Password, setPassword] = useState('');
    const [errors, setErrors] = useState({ UserName: '', Password: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [showPwd, setShowPwd] = useState(false);
    function togglepwd(e) {
        e.preventDefault();
        setShowPwd(!showPwd);
    }

    const handleLoginSubmit = () => {
        setErrorMessage('');
        setErrors({ UserName: '', Password: '' });
        if (!UserName) {
            setErrors({ UserName: 'User Name is required', Password: '' });
            return;
        }
        else if (UserName && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(UserName)) {
            setErrors({ UserName: 'Must match the User Name format', Password: '' });
            return;
        }
        else if (!Password) {
            setErrors({ UserName: '', Password: 'Password is required' });
            return;
        }

        let request = {
            admin_email: UserName,
            password: Password
        };
        API.post('admin/login', request).then((response) => {
            // navigate("/dashboard");

            if (response.data.success == true) {
                localStorage.setItem("token", true)
                ls.set('userDetails', response.data.data);
                dispatch(LoginAction.success(response.data.data));
                ls.set("languageId", "all");
                navigate('/dashboard');
            }
            else {
                // setTimeout(() => {
                setErrorMessage(response.data.error.err);
                //   }, 100);
            }
        });
    };


    return (
        <div className="container-fluid login-page">
            <div className="row">
                <div className="login-Left-sec col-lg-6">
                    <div className="row">
                        <div className="loginpage-logo">
                            <img src={Logo_final} alt="logo" />
                        </div>
                        <h1>Login</h1>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="Username" className="form-label">User  Name</label>
                                <input type="text" className="form-control" id="Username" placeholder="Enter User Name" onChange={(e) => setUserName(e.target.value)} />
                                <p className="form-input-error">{errors.UserName}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">

                            <div className="mb-5">
                                <label htmlFor="Password" className="form-label">Password</label>
                                <div className="col-md-12 tbox p-0">
                                    <i htmlFor="psw" className="passwordeye" onClick={togglepwd}>{showPwd === true ? eye : eyeslash}</i>
                                    <input type={showPwd ? "text" : "password"} className="form-control" id="Password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <p className="form-input-error">{errors.Password}{errorMessage}</p> 
                               
                            </div>
                        </div>
                        
                        <div className="login-button">
                            <a onClick={handleLoginSubmit} type="button" className="btn btn-login">Login</a>
                        </div>
                    </div>

                </div>
                <div className="login-right-sec col-lg-6 p-0">
                    <img src={loginimg} alt="login images" className="rigt-side-img" />
                    <img src={Logo_final} alt="logo" className="rigt-side-logo" />

                </div>

            </div>

        </div>
    );
};
export default Login;
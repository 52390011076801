import React, { useState , useEffect} from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';


const ConfigEdit = () => {
    const AdminId = ls.get("AdminId");
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [calllimit, setcalllimit] = useState("");
  


    console.log("calllimit",calllimit);
   // const [operatorID, setoperatorID] = useState("");
   // console.log(operatorID,"operatorID");
  
   // const [Selectedoperator, setSelectedoperator] = useState("");


    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [loading, setLoading] = useState(true)

   
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    // useEffect(() => {
    //     OperatorList();
    // }, [])

    // const OperatorList = () => {
    //     API.post('mapOperatorlist/condition',).then((response) => {
          

    //             setoperatorID(response.data.data);

           
    //     });
    // }

    useEffect(() => {
        Configview();
    }, [])

    const Configview = () => {
        let request = {
            calllimit_id: AdminId,
        };
        API.post('calllimitbyid/condition ', request).then((response) => {
            if (response.data.success == true) {
                setcalllimit(response.data.data[0].calllimit);
              
                setLoading(false);
            }
        });
    }

    const handleEdit = () => {
       
        
        let request = {

            calllimit: calllimit,
           
            calllimit_id: AdminId,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('calllimit/update', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("edit New Config");
                setPopupMsg("Config Updated  Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/CustomerCountPage");
            }
            else {
                // setTimeout(() => {
              
                //   }, 100);
                togglePopup();
                setPopupTitle("edit New Config");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

   

    // const handleOperatorNameId = (e) => {
    //     setoperator(e.target.value);
    //     const selectedLangDetails = operatorID.filter(data => data.operator_id == e.target.value)[0]
    //     setSelectedoperator(selectedLangDetails);
    // }
    //   console.log(Selectedoperator);


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                   
                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/CustomerCountPage')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Edit Config</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                            {loading ? <Loading /> :
                                <form>
                                    <div className="row">
                                       

                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">call Limit <span className='mandatory ms-1'>*</span></label>
                                            <input type="username" className="form-control" id="username" value={calllimit} onChange={(e) => setcalllimit(e.target.value)} />
                                           
                                        </div>
                                        
                                        
                                    </div>
                                </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleEdit}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ConfigEdit;
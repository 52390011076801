import React, { useState, useEffect } from 'react';
import Header from '../Header/header';

import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';



const Admin = () => {
    const [adminInfo, setAdminInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [adminName, setAdminName] = useState("");
    const [userName, setUserName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [PageNn, setPageNo] = useState("");
    const [Count, setCount] = useState("");
    const [status, setStatus] = useState("");


    const pageLimit = PageNn;

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        AdminList();
    }, [])
    // ============================List===============================
    const AdminList = () => {
        API.post('adminList/condition',).then((response) => {
            if (response.data.success == true) {
                setAdminInfo(response.data?.data);
                setTotalRecords(response.data.data?.length);
                setCount(response.data.data1[0].count);
                document.getElementById("page").value = "10";
                setCurrentPage(1);
                setLoading(false);
                setPageNo(10);
            }
        });
    }

    // =========================Search============================

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const adminSearch = () => {
        let request = {
            data: search
        }

        API.post("adminsearch/condition", request).then(response => {
            setAdminInfo(response.data?.data);
            setCount(response.data.data1[0].count);
            setTotalRecords(response.data.data?.length);
            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                adminSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            AdminList();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            adminSearch();
        }
    };
    //const yesterday = moment().subtract(1, 'day');
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const adminFilter = () => {
        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            admin_name: adminName,
            user_name: userName,
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            active: status
        }

        API.post("adminfilter/condition", request).then(response => {
            setAdminInfo(response.data?.data);
            setTotalRecords(response.data.data?.length);
            setCount(response.data.data1[0].count);
            setLoading(false)
            document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const handleSearch = () => {
        adminFilter();
    }

    const handleclear = () => {
        setAdminName("");
        setUserName("");
        setPhoneNo("");
        setfromdate("");
        settodate("");
        setStatus("");
    
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
    
        setLoading(true);
        AdminList();
        setFromDateError();
        document.getElementById("page").value = "10";
    };

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }

    function formatTime(value) {
        let local = ""
        // let offset = ""
        // let utc1 = ""
        // console.log(value, "yyyy1");
        value = value.replace(" ", "T")
        value = value + "Z";
        console.log(value, "yyyy1");
        local = new Date(value);
        value = local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
        value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }

    const redirection = (id) => {

        navigate({ pathname: "/AdminView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    //asperpage

    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.admin_id - userB.admin_id;
            }
            return userB.admin_id - userA.admin_id;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
    };

    const sortByUserName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.user_name.localeCompare(userA.user_name);
            }
            return userA.user_name.localeCompare(userB.user_name);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "username", reversed: !sorted.reversed });
    };

    const sortByFirstName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.first_name.localeCompare(userA.first_name);
            }
            return userA.first_name.localeCompare(userB.first_name);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "firstname", reversed: !sorted.reversed });
    };

    const sortByLastName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.last_name.localeCompare(userA.last_name);
            }
            return userA.last_name.localeCompare(userB.last_name);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "lastname", reversed: !sorted.reversed });
    };

    const sortByEmail = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.admin_email.localeCompare(userA.admin_email);
            }
            return userA.admin_email.localeCompare(userB.admin_email);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "email", reversed: !sorted.reversed });
    };

    const sortByPassword = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.password.localeCompare(userA.password);
            }
            return userA.password.localeCompare(userB.password);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "password", reversed: !sorted.reversed });
    };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.phone_no - userB.phone_no;
            }
            return userB.phone_no - userA.phone_no;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "phone", reversed: !sorted.reversed });
    };

    const sortByDateTime = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.createdtime.localeCompare(userA.createdtime);
            }
            return userA.createdtime.localeCompare(userB.createdtime);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "datetime", reversed: !sorted.reversed });
    };







    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Admin</h5>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="common-heading-sec">
                                <div className="col-lg-2 me-2">
                                    <input type="text" className="form-control" value={adminName} placeholder=" Admin Name" onChange={(e) => setAdminName(e.target.value)} />
                                </div>
                                <div className="col-lg-2 me-2">
                                    <input type="text" className="form-control" value={userName} placeholder=" User Name" onChange={(e) => setUserName(e.target.value)} />
                                </div>
                                <div className="col-lg-2 me-2">
                                    <input type="text" className="form-control" value={phoneNo} placeholder=" Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>

                                <div className="datePickerBlock">
                                    {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>


                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>
                                <div className="col-lg-1 me-2">
                                        {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >Status</option>
                                            <option value="1">Active</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                        </div>



                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            </div>
                        </div>

                        
                    </div>


                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>
                        </span>

                        <div className='col-xl-4'>
                            <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" value={search} placeholder="Name or Id or Phone# " onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>

                                <div className="add-btn" onClick={() => navigate('/adminAdd')}><i className="fa-solid fa-plus"></i>Add</div>
                            </div>
                        </div>


                    </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">

                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByUserName}>User Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByFirstName}>First Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByLastName}>Last Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByEmail}>Email Id<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPassword}>Password<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDateTime}>Date & Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Status</th>
                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminInfo.length > 0 ? adminInfo.sort((a, b) => b.customer_id - a.customer_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((adminInfo, index) =>
                                                <tr key={index}>
                                                    <td><a onClick={() => redirection(adminInfo.admin_id)}>{adminInfo.admin_id}</a></td>
                                                    <td><a onClick={() => redirection(adminInfo.admin_id)}>{adminInfo.user_name}</a></td>
                                                    <td>{adminInfo.first_name}</td>
                                                    <td>{adminInfo.last_name}</td>
                                                    <td>{adminInfo.admin_email}</td>
                                                    <td>{adminInfo.password}</td>
                                                    <td>{formatMobileNO(adminInfo.phone_no)}</td>
                                                    <td>{formatTime(adminInfo.createdtimes)}</td>
                                                    <td>{adminInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>
                                                    <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate('/adminEdit', ls.set("AdminId", adminInfo.admin_id))}>Edit</a>
                                                        <a className="edit" onClick={() => navigate('/AdminView', ls.set("AdminId", adminInfo.admin_id))}>View</a></td>

                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}

                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default Admin;
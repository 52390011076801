import React from "react";
import PropTypes from 'prop-types';
import './popup.css';

const RecyclePopup = props => {
  const {content, handleClose, isClose} = props
  return (
    <div >
        {isClose && <span className="close-icon" onClick={handleClose}>x</span> }
        {content}
      </div>
   
  );
};
RecyclePopup.propTypes = {
  handleClose: PropTypes.func,
  content: PropTypes.string,
  isClose: PropTypes.bool, 
}
 
export default RecyclePopup;
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import checkImg from '../../assets/img/check.png';
import errorImg from '../../assets/img/uncheck.png';
import warningImg from '../../assets/img/warning.png';
import "./commonPopup.css"
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/font-awesome/css/all.css';

const CommonPopup = props => {
    const navigate = useNavigate();

    // Example values for reference

    // const { handleClose, 
    //     popupTitle = "ERROR",
    //     popupMsg = "something went wrong please try again", 
    //     popupType = 'error',             //("success or error or confirm")
    //     popupActionType = 'close',       //("redirect  or close or confirm or refresh")
    //     popupActionValue = 'close',      //("any string you like to display")
    //     popupActionPath } = props;       //("where you like to redirect")

    const { handleClose, popupTitle, popupMsg, popupType, popupActionType, popupActionValue, popupActionPath, Confirmation } = props;
    return (
        <div className="popup-box">
            <div id="" className="CommonModels-box">
                <div className="Commonfullformblock col-lg-7">
                    <div className="CommonContainer">
                        <div className="CommonModalcontent">
                            <div className="CommonModalbody">
                                {popupTitle !== "" && <h2>{popupTitle}</h2>}
                            </div>
                            <div className="Commonfull-icon">
                                <img className={popupType.toLowerCase() === "success" ? "successImg" : popupType.toLowerCase() === "error" ? "errorImg" : warningImg} alt="" src={popupType.toLowerCase() === "success" ? checkImg : popupType.toLowerCase() === "error" ? errorImg : warningImg} />
                            </div>
                            <div className="CommonModalbody">
                                <p>{popupMsg}</p>
                            </div>
                            <div className="CommonModalfooter ">


                                {popupActionType.toLowerCase() === "confirm" && (<div className="CommonModalfooter session">
                                    <button className="cta-btn btn-smlprimary" onClick={handleClose} >CANCEL</button>
                                    <button className="btn btn-smlprimary" onClick={Confirmation} >OK</button>
                                </div>)}

                                {popupActionType.toLowerCase() === "redirect" && <a className="btn btn-smlprimary" onClick={() => navigate(popupActionPath)} >{popupActionValue}</a>}
                                {popupActionType.toLowerCase() === "close" && <button className="btn btn-smlprimary" onClick={handleClose} >{popupActionValue}</button>}
                                {popupActionType.toLowerCase() === "refresh" && <a className="btn btn-smlprimary" href={popupActionPath}>{popupActionValue}</a>}

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
}

CommonPopup.propTypes = {
    handleClose: PropTypes.func,
    popupTitle: PropTypes.string,
    popupMsg: PropTypes.string,
    popupType: PropTypes.string,
    popupActionType: PropTypes.string,
    popupActionValue: PropTypes.string,
    popupActionPath: PropTypes.string,
    Confirmation: PropTypes.func
}

export default CommonPopup;